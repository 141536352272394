import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import style from './registration.module.scss';
import { Paneltilte, Buttons } from './';
import FormsControl from './FormsControl';
import { useSelector } from "react-redux";
import { First } from 'react-bootstrap/esm/PageItem';

function ContactInformation(props) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const [data, setData] = useState([])
    //change handler
    const changehandler = (e) => {
        console.log(e);
        props.ChangeHandler(e, 'contactInfo')
    }
    const sendOTP = () => {
        props.sendOTP()

    }
    useEffect(() => {
        console.log(typeof (props.data));
        console.log(props.data);
        let newData = props.data.map(object => {
            if (object.code === 'gender') {
                let newOption = object.options.map((option) => {
                    return {
                        ...option,
                        code: ('profile.' + option.code)
                    }
                })
                return {
                    ...object,
                    options: newOption
                }
            } else {
                return object
            }
        });
        setData(newData);
    }, [props.data])
    return (
        <div className={`${currentTheme == "theme-dark"
            ? `${style.dark_mode_SponsorPackage}`
            : `${style.SponsorPackage}`
            }`}>
            <Paneltilte
                tilte="contactInformation"
            />
            <Form onSubmit={(e) => { props.submit(e, "", 'contactInfo') }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {data.map((field, index) => (
                        <React.Fragment key={index}>
                            {index === 0 || index === 1 ? (
                                <div style={{ width: '50%', padding: "8px" }}>
                                    <FormsControl
                                        data={field}
                                        change={changehandler}
                                        refs={props.refs}
                                        selectDate={props.selectDate}
                                    />
                                </div>
                            ) : (
                                <div style={{ width: '100%' }}>
                                    {console.log(field)}
                                    <FormsControl
                                        data={{
                                            ...field , companyName: data.find(item => item.code === "companyName")?.value || null
                                        }}
                                        change={changehandler}
                                        refs={props.refs}
                                        selectDate={props.selectDate}
                                        sendOTP={sendOTP}
                                        buttonActive={props.buttonActive}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <Buttons
                    prev={true}
                    prevButtonClick={props.prevButtonClick}
                />
            </Form>

        </div>
    )
}

export default ContactInformation
