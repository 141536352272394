import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import API from "../../api/api";

//import Nophoto from '../../assets/images/nophoto/no_photo.jpg'

export default class DashboardService {
  key = localStorage.getItem("apiKey");

  dashboardTiles = async () => {
    return await API.API.get("home/dashboard_tile").then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    });
  };

  DashboardLoad = async () => {
    return await API.API.get("home/dashboard")
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  UnapprovedDashboardLoad = async () => {
    return await API.API.get("common/unapprovedDashboard")
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  ugradedashbord = async () => {
    return await API.API.get("common/products")
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  JoiningGraphFilter = async (range) => {
    return await API.API.get("home/graphFilter?range=" + range)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
  
  TileFilter = async (type, range) => {
    return await API.API.get(
      "home/tileFilter?range=" + range + "&type=" + type
    ).then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    });
  };

  dashboardJoining = async ()=>{
    return await API.API.get("home/latest_members").then((res)=>{
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
  }

  dashboardEarnings = async ()=>{
    return await API.API.get("home/earnings").then((res)=>{
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    })
  }


  // async dashboardEarnings() {
  //   return new Promise((resolve, reject) => {
  //     fetch(BASE_URL + "home/earnings", {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         "api-key": this.key,
  //         "access-token": UserStore.key,
  //       },
  //     })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }


  // async dashboardJoining() {
  //   return new Promise((resolve, reject) => {
  //     fetch(BASE_URL + "home/latest_members", {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         "api-key": this.key,
  //         "access-token": UserStore.key,
  //       },
  //     })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  //dashboard tiles
  // async dashboardTiles(){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'home/dashboard_tile', {
  //             method: 'POST',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //profile data
  async dashboardUser() {
    return new Promise((resolve, reject) => {
      fetch(BASE_URL + "home/user_dashboard", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  
 




  //chart
  async DashboardChart(range = "monthly_joining_graph") {
    return new Promise((resolve, reject) => {
      fetch(BASE_URL + "home/latest_member_joining?range=" + range, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

//upload unapproved user 
  upload_reciept(reciept){
    return new Promise((resolve, reject) =>{
        fetch(BASE_URL+'common/bankUpload', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,   // API key
                'access-token'  : UserStore.key
            },
            body: reciept  //convert to json
            })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    })
}


  //dashboard main view api service
  // async DashboardLoad(){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'home/dashboard', {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {

  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //joining graph fiter api call
  // async JoiningGraphFilter(range){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'home/graphFilter?range='+range, {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }

  //tiles item filter changes
  // async TileFilter(type,range){
  //     return new Promise((resolve, reject) =>{
  //         fetch(BASE_URL+'home/tileFilter?range='+range+'&type='+type, {
  //             method: 'GET',
  //             headers : {
  //                 'Accept'        : 'application/json',
  //                 'Content-Type'  : 'application/json',
  //                 'api-key'       : this.key,
  //                 'access-token'  : UserStore.key
  //             },
  //             })
  //             .then((response) => {
  //                 return response.json();
  //             })
  //             .then((response) => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     })
  // }
}
