import React, { useState, useEffect } from "react";
import style from "./dashboard.module.scss";
import "./Earnandperform.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "react-responsive-tabs/styles.css";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const brdercontent = {
  marginBottom: "20px",
  paddingBottom: "30px",
  borderBottom: "1px #e5e5e5 solid",
};

function OrderDetails({ orderDetails, lodervalue }) {
  //income,expenses,payoutstatus
  const { currentTheme } = useSelector((state) => state.Theme);
  let themevalue;
  if (currentTheme == "theme-dark") {
    themevalue = true;
  } else {
    themevalue = false;
  }

  const [content, setContent] = useState("orderDetails");
  const curr = useSelector((state) => state.curr);

  const { t } = useTranslation();

  return (
    <div className={style.dashbrd_earing_exp_sec}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_dashboard_pannel_box}`
            : `${style.dashboard_pannel_box}`
        }`}
      >
        {lodervalue ? (
          <>
            <div className={style.dashboard_new_member_head}>
              <Skeleton width={250} count={1} />
            </div>
            <div className="earn_n_team_content-tabs">
              <div className={style.earnings} style={brdercontent}></div>
              <Skeleton width={120} count={1} />
            </div>
          </>
        ) : (
          <>
            <div
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_dashboard_new_member_head}`
                  : `${style.dashboard_new_member_head}`
              }`}
            >
              Purchased Package
            </div>

            <div className={style.dashbord_earning_expence_cnt_sc}>
              <div className="earn_n_team_content-tabs">
                <div
                  className={
                    content === "orderDetails"
                      ? "earn_n_team_content earn_n_team_active-content"
                      : "earn_n_team_content"
                  }
                >
                  {orderDetails.length > 0 ? (
                    orderDetails.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div className={style.earnings} style={brdercontent}>
                            <div className={style.earning_tabs_row}>
                              <div
                                className={`${
                                  currentTheme == "theme-dark"
                                    ? `${style.dark_mode_earning_tabs_name}`
                                    : `${style.earning_tabs_name}`
                                }`}
                              >
                                {obj.package}

                                <span>
                                  {curr.currentCurr}{" "}
                                  {(obj.price * curr.value).toFixed(
                                    curr.precision
                                  )}
                                </span>
                              </div>
                              <div className={style.earning_right_ico}>
                                <span>{obj.quantity}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={`${style.NodataImage}`}>
                      <center>
                        <img
                          src={nodata}
                          alt="nodata"
                          style={{ width: "50%" }}
                        />
                      </center>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default OrderDetails;
