// // import React, { useState, useEffect } from "react";
// // import { Form } from "react-bootstrap";
// // import { useTranslation } from "react-i18next";
// // import { usePopperTooltip } from "react-popper-tooltip";
// // import { animated, useTransition } from "react-spring";
// // import DatePickers from "../common/Datepickers";
// // import "react-popper-tooltip/dist/styles.css";
// // import style from "./registration.module.scss";

// // function FormsControl({ policy, validation, ...props }) {
// //   const { t } = useTranslation();
// //   const [controlledVisible, setControlledVisible] = useState(false);
// //   const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
// //     usePopperTooltip({
// //       visible: controlledVisible,
// //       onVisibleChange: setControlledVisible,
// //       trigger: "focus",
// //     });
// //   const transitions = useTransition(controlledVisible, null, {
// //     from: { opacity: 0 },
// //     enter: { opacity: 1 },
// //     leave: { opacity: 0 },
// //   });

// //   const Components = (type) => {
// //     console.log(props.data.code);
// //     if (
// //       type === "text" ||
// //       type === "password" ||
// //       type === "number" ||
// //       type === "email" ||
// //       type === "textarea"
// //     ) {
// //       return (
// //         <Form.Control
// //           ref={
// //             props.data.code === "password" &&
// //             props.data.validation.disableHelper
// //               ? setTriggerRef
// //               : null
// //           }
// //           id={props.data.code}
// //           type={type}
// //           name={props.data.code}
// //           placeholder={props.data.value ?? ""}
// //           disabled={!props.data.isEditable}
// //           className={`
// //                         ${style.inputField}
// //                         ${
// //                           props.data.error
// //                             ? `${style.formsHasError}form-control is-invalid`
// //                             : ""
// //                         }
// //                         `}
// //           onChange={(e) => props.change(e, props.data.required)}
// //           onBlur={() => {
// //             if (
// //               props.data.code === "sponsorUserName" ||
// //               props.data.code === "password" ||
// //               props.data.code === "userName"
// //             ) {
// //               props.blurhandler(props.data.code);
// //             }
// //           }}
// //         />
// //       );
// //     } else if (type === "tel") {
// //       return (
// //         <Form.Control
// //           ref={
// //             props.data.code === "password" &&
// //             props.data.validation.disableHelper
// //               ? setTriggerRef
// //               : null
// //           }
// //           id={props.data.code}
// //           type={type}
// //           pattern={"[0-9]{2,3}[0-9]{4,6}[0-9]{3}[0-9]{3}"}
// //           placeholder={`Eg: 00918912345677`}
// //           name={props.data.code}
// //           value={props.data.value ?? ""}
// //           disabled={!props.data.isEditable}
// //           className={`
// //                 ${style.inputField}
// //                 ${
// //                   props.data.error
// //                     ? `${style.formsHasError}form-control is-invalid`
// //                     : ""
// //                 }
// //                 `}
// //           onChange={(e) => props.change(e, props.data.required)}
// //           onBlur={() => {
// //             if (
// //               props.data.code === "sponsorUserName" ||
// //               props.data.code === "password" ||
// //               props.data.code === "userName"
// //             ) {
// //               props.blurhandler(props.data.code);
// //             }
// //           }}
// //         />
// //       );
// //     } else if (type === "select") {
// //       return (
// //         <Form.Control
// //           as="select"
// //           disabled={props.status || props.data.disabled}
// //           className={`${style.inputField}`}
// //           name={props.data.code}
// //           onChange={(e) => props.change(e, props.data.required)}
// //           // onChange={ (e)=>{props.dropdownChange(e.target.value,props.data)}}
// //           value={props.data.value ?? ""}
// //         >
// //           <option value="">
// //             {t("registration.selectOption", { field: props.data.code })}
// //           </option>
// //           {props.data.options.map((option, index) => {
// //             return (
// //               <option key={index} value={option.value}>
// //                 {t(option.code)}
// //               </option>
// //             );
// //           })}
// //         </Form.Control>
// //       );
    // } else if (type === "select1") {
    //   return (
    //     // <Form.Control
    //     //   as="text"
    //     //   disabled={true} // Set to true to make the dropdown non-editable
    //     //   className={`${style.inputField}`}
    //     //   name={props.data.code}
    //     //   onChange={(e) => props.change(e, props.data.required)}
    //     //   value={props.data.value ?? ""}
    //     //   placeholder = {props.data.options.name}
    //     // >

    //     <Form.Control
    //       id={props.data.code}
    //       type={type}
    //       name={props.data.code}
    //       placeholder={props.data.options.name ?? ""}
    //       disabled={!props.data.isEditable}
    //       // value={props.data.value ?? ""}
    //       className={`
    //                     ${style.inputField}
    //                     ${
    //                       props.data.error
    //                         ? `${style.formsHasError}form-control is-invalid`
    //                         : ""
    //                     }
    //                     `}
    //       onChange={(e) => props.change(e, props.data.required)}
    //     />
    //   );
// //     } else if (type === "checkbox") {
// //       return (
// //         <div className={style.checkbox}>
// //           <input
// //             type="checkbox"
// //             name={props.data.code}
// //             className={style.checkboxInput}
// //             onChange={(e) => props.change(e, props.data.required)}
// //             value={props.data.value}
// //             defaultChecked={props.data.value}
// //             // ref={el => (this.selector = el)}
// //             id="terms"
// //             // {...inputProps}
// //           />
// //           <label
// //             className={`${style.checkboxLabel} ${
// //               props.data.required && style.required
// //             }  ${props.data.error && style.isInvalid}`}
// //             onClick={() => props.openModal(props.data.content)}
// //           >
// //             {t("Common.acceptTermsAndCondietion")}
// //           </label>
// //         </div>
// //       );
// //     } else if (type === "date") {
// //       return (
// //         <DatePickers
// //           disabled={false}
// //           start={props.data.value}
// //           datechange={props.selectDate}
// //           id={props.data.code}
// //         />
// //       );
// //     }
// //   };
// //   return (
// //     <Form.Group>
// //       {props.data.code !== "agree_terms" && (
// //         <Form.Label
// //           htmlFor={props.data.code}
// //           className={`${props.data.required && style.required} ${
// //             props.data.error && style.isInvalid
// //           }`}
// //         >
// //           {props.data.custom
// //             ? props.data.code
// //             : t("profile." + props.data.code)}
// //         </Form.Label>
// //       )}
// //       {Components(props.data.type)}

// //       {props.data.code === "password" &&
// //         props.data.validation.disableHelper &&
// //         transitions.map(
// //           ({ item, key, props }) =>
// //             item && (
// //               <animated.div
// //                 key={key}
// //                 ref={setTooltipRef}
// //                 {...getTooltipProps({
// //                   className: "tooltip-container",
// //                   style: props,
// //                 })}
// //               >
// //                 <div>
// //                   {Object.keys(validation).map(function (key, index) {
// //                     if (key !== "disableHelper") {
// //                       return (
// //                         <p key={index}>
// //                           {policy[key] ? (
// //                             <i
// //                               className="fa fa-check-circle"
// //                               style={{ color: "green" }}
// //                             ></i>
// //                           ) : (
// //                             <i
// //                               className="fa fa-times-circle"
// //                               style={{ color: "#f05050" }}
// //                             ></i>
// //                           )}
// //                           {t("validation.atleast_" + key, {
// //                             count: validation[key],
// //                           })}
// //                         </p>
// //                       );
// //                     }
// //                     return false;
// //                   })}
// //                 </div>
// //                 <div {...getArrowProps({ className: "tooltip-arrow" })} />
// //               </animated.div>
// //             )
// //         )}

// //       {/* error message */}
// //       <Form.Text
// //         className={`
// //                 ${props.data.error ? style.isInvalid : "d-none"}
// //                 `}
// //       >
// //         {props.data.custom
// //           ? "Please enter " + props.data.code
// //           : t(props.data.error, props.data.errorField)}
// //       </Form.Text>
// //     </Form.Group>
// //   );
// // }

// // export default FormsControl;





// import React, { useState, useEffect } from 'react';
// import { Form } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
// import { usePopperTooltip } from 'react-popper-tooltip';
// import { animated, useTransition } from 'react-spring';
// import DatePickers from '../common/Datepickers'
// import 'react-popper-tooltip/dist/styles.css';
// import style from './registration.module.scss';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import { Button } from "react-bootstrap";


// function FormsControl({ policy, validation, ...props }) {
//     const { t } = useTranslation();
//     const [controlledVisible, setControlledVisible] = useState(false);
//     const {
//         getArrowProps,
//         getTooltipProps,
//         setTooltipRef,
//         setTriggerRef,
//     } = usePopperTooltip({
//         visible: controlledVisible,
//         onVisibleChange: setControlledVisible,
//         trigger: 'focus'
//     });
//     const transitions = useTransition(controlledVisible, null, {
//         from: { opacity: 0 },
//         enter: { opacity: 1 },
//         leave: { opacity: 0 },
//     });
//     console.log(props.data, "field");

//     const Components = type => {
//         if (props.code === "companyName" && type === 'text' || type === 'password' || type === 'number' || type === 'email' || type === "textarea") {
//             return <Form.Control
//                 ref={(props.data.code === 'password' && props.data.validation.disableHelper) ? setTriggerRef : null}
//                 id={props.data.code}
//                 type={type}
//                 name={props.data.code}
//                 value={props.data.value ?? ''}
//                 disabled={!props.data.isEditable}
//                 className={`
//                         ${style.inputField}
//                         ${props.data.error ? `${style.formsHasError}form-control is-invalid` : ""}
//                         `}
//                 onChange={e => props.change(e, props.data.required)}
//                 onBlur={() => {
//                     if (props.data.code === 'sponsorUserName' || props.data.code === 'password' || props.data.code === 'userName') {
//                         props.blurhandler(props.data.code)
//                     }
//                 }}
//             />
//         } else if (type === "tel") {
//             return (
//                 <div style={{ display: 'flex' }}>
//                     <PhoneInput
//                         ref={
//                             props.data.code === 'password' &&
//                                 props.data.validation.disableHelper === 0
//                                 ? setTriggerRef
//                                 : null
//                         }
//                         inputProps={{
//                             id: props.data.code,
//                             name: props.data.code,

//                             placeholder: 'Enter phone number',
//                         }}
//                         country={'us'}

//                         inputStyle={{
//                             marginRight: '20px',
//                             width: "100%"
//                         }}
//                         value={props.data.value ?? ''}
//                         disabled={!props.data.isEditable}
//                         onChange={(value, country, event) => props.change(event, props.data.required)}
//                         onBlur={() => {
//                             if (
//                                 props.data.code === 'sponsorUserName' ||
//                                 props.data.code === 'password' ||
//                                 props.data.code === 'userName'
//                             ) {
//                                 props.blurhandler(props.data.code);
//                             }
//                         }}
//                         style={{ marginRight: "15px", width: "100%" }}
//                     />
//                     <Button className={`btn-pill ${style.NextButton}`} disabled={props.buttonActive} onClick={props.sendOTP} style={{ width: '35%' }}>
//                         send OTP
//                     </Button>
//                 </div>

//             );
//         } else if (type === "select" && props.data.code === "individual_type") {
//             return (
//                 <>
//                     <Form.Control
//                         as="select"
//                         disabled={props.status || props.data.disabled}
//                         className={`${style.inputField}`}
//                         name={props.data.code}
//                         onChange={(e) => props.change(e, props.data.required)}
//                         // onChange={ (e)=>{props.dropdownChange(e.target.value,props.data)}}
//                         value={props.data.value ?? ""}
//                     >
//                         <option value="">
//                             {t("registration.selectOption", { field: props.data.code })}
//                         </option>
//                         {props.data.options.map((option, index) => {
//                             return (
//                                 <option key={index} value={option.value}>
//                                     {t(option.code)}
//                                 </option>
//                             );
//                         })}
//                     </Form.Control>
//                     {props.data.value === "company" && (<>
//                         <Form.Label
//                             htmlFor={props.data.code}
//                             className={`${props.data.required && style.required} ${props.data.error && style.isInvalid
//                                 }`}
//                         >
//                             Company Name
//                         </Form.Label>
//                         <Form.Control
//                             ref={
//                                 props.data.code === "password" &&
//                                     props.data.validation.disableHelper
//                                     ? setTriggerRef
//                                     : null
//                             }
//                             id={"companyName"}
//                             type={
//                                 "text"
//                             }
//                             name={"companyName"}
//                             value={props.data.companyName ?? ""}
//                             // disabled={!props.data.isEditable}

//                             onChange={(e) => props.change(e, false)}
//                             inputStyle={{
//                                 marginRight: '20px',
//                                 width: "100%"
//                             }}
//                         /></>)
//                     }
//                 </>
//             );
//         } else if (type === 'select') {
//             return <Form.Control
//                 as="select"
//                 disabled={props.status || props.data.disabled}
//                 className={`${style.inputField}`}
//                 name={props.data.code}
//                 onChange={e => props.change(e, props.data.required)}
//                 // onChange={ (e)=>{props.dropdownChange(e.target.value,props.data)}}
//                 value={props.data.value ?? ''}
//             >
//                 <option value=''>{t('registration.selectOption', { field: props.data.code })}</option>
//                 {
//                     props.data.options.map((option, index) => {
//                         return <option key={index} value={option.value}>{t(option.code)}</option>
//                     })
//                 }

//             </Form.Control>

//         } else if (type === "select1") {
//             return (
//                 // <Form.Control
//                 //   as="text"
//                 //   disabled={true} // Set to true to make the dropdown non-editable
//                 //   className={`${style.inputField}`}
//                 //   name={props.data.code}
//                 //   onChange={(e) => props.change(e, props.data.required)}
//                 //   value={props.data.value ?? ""}
//                 //   placeholder = {props.data.options.name}
//                 // >

//                 <Form.Control
//                     id={props.data.code}
//                     type={type}
//                     name={props.data.code}
//                     placeholder={props.data.options.name ?? ""}
//                     disabled={!props.data.isEditable}
//                     // value={props.data.value ?? ""}
//                     className={`
//                                 ${style.inputField}
//                                 ${props.data.error
//                             ? `${style.formsHasError}form-control is-invalid`
//                             : ""
//                         }
//                                 `}
//                     onChange={(e) => props.change(e, props.data.required)}
//                 />
//             );

//         } else if (type === 'checkbox') {
//             return <div className={style.checkbox}>
//                 <input
//                     type="checkbox"
//                     name={props.data.code}
//                     className={style.checkboxInput}
//                     onChange={e => props.change(e, props.data.required)}
//                     value={props.data.value}
//                     defaultChecked={props.data.value}
//                     // ref={el => (this.selector = el)}
//                     id='terms'
//                 // {...inputProps}
//                 />
//                 <label className={`${style.checkboxLabel} ${props.data.required && style.required}  ${props.data.error && style.isInvalid}`} onClick={() => props.openModal(props.data.content)}>{t('Common.acceptTermsAndCondietion')}</label>
//             </div>
//         } else if (type === 'date') {
//             return <DatePickers
//                 disabled={false}
//                 start={props.data.value}
//                 datechange={props.selectDate}
//                 id={props.data.code}
//             />
//         }
//     }
//     return (
//         <Form.Group >
//             {
//                 props.data.code !== 'agree_terms' &&
//                 <Form.Label htmlFor={props.data.code} className={`${props.data.required && style.required} ${props.data.error && style.isInvalid}`}>
//                     {props.data.custom ?
//                         props.data.code
//                         :
//                         t("profile." + props.data.code)
//                     }
//                 </Form.Label>
//             }
//             {Components(props.data.type)}


//             {

//                 (props.data.code === 'password' && props.data.validation.disableHelper) &&
//                 transitions.map(({ item, key, props }) =>
//                     item && (
//                         <animated.div
//                             key={key}
//                             ref={setTooltipRef}
//                             {...getTooltipProps({
//                                 className: 'tooltip-container',
//                                 style: props,
//                             })}
//                         >
//                             <div>
//                                 {
//                                     Object.keys(validation).map(function (key, index) {
//                                         if (key !== 'disableHelper') {
//                                             return <p key={index}>
//                                                 {
//                                                     policy[key] ?
//                                                         <i className="fa fa-check-circle" style={{ color: "green" }}></i> :
//                                                         <i className="fa fa-times-circle" style={{ color: "#f05050" }}></i>
//                                                 }
//                                                 {t('validation.atleast_' + key, { count: validation[key] })}
//                                             </p>
//                                         }
//                                         return false
//                                     })
//                                 }
//                             </div>
//                             <div {...getArrowProps({ className: 'tooltip-arrow' })} />
//                         </animated.div>
//                     )
//                 )
//             }

//             {/* error message */}
//             <Form.Text className={`
//                 ${props.data.error
//                     ? style.isInvalid
//                     : "d-none"}
//                 `}>
//                 {props.data.custom ?
//                     "Please enter " + props.data.code
//                     :
//                     t(props.data.error, props.data.errorField)
//                 }
//             </Form.Text>
//         </Form.Group>
//     )
// }

// export default FormsControl;



import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { usePopperTooltip } from "react-popper-tooltip";
import { animated, useTransition } from "react-spring";
import DatePickers from "../common/Datepickers";
import "react-popper-tooltip/dist/styles.css";
import style from "./registration.module.scss";
import { useSelector } from "react-redux";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button } from "react-bootstrap";
import companyName from "../../../store/mobxStore/companyName";

function FormsControl({ policy, validation, ...props }) {
  console.log("data",props.data)
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const phoneInputRef = useRef();
  const [controlledVisible, setControlledVisible] = useState(false);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      visible: controlledVisible,
      onVisibleChange: setControlledVisible,
      trigger: "focus",
    });
  const transitions = useTransition(controlledVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const Components = (type) => {
    if (props.data.code !== "companyName" && type === "text" || type === "password" || type === "number" || type === "email" || type === "textarea") {
      return (
        <Form.Control
          ref={
            props.data.code === "password" &&
              props.data.validation.disableHelper
              ? setTriggerRef
              : null
          }
          id={props.data.code}
          type={type}
          name={props.data.code}
          value={props.data.value ?? ""}
          disabled={!props.data.isEditable}
          className={`${currentTheme == "theme-dark"
            ? `
              ${style.dark_mode_inputField}
              ${props.data.error
              ? `${style.formsHasError}form-control is-invalid`
              : ""
            }
              `
            : `
              ${style.inputField}
              ${props.data.error
              ? `${style.formsHasError}form-control is-invalid`
              : ""
            }
              `
            }`}
          onChange={(e) => props.change(e, props.data.required)}
          onBlur={() => {
            if (
              props.data.code === "sponsorUserName" ||
              props.data.code === "password" ||
              props.data.code === "userName"
            ) {
              props.blurhandler(props.data.code);
            }
          }}
        />
      );
    } else if (type === "tel") {
      return (
        <div style={{ display: 'flex' }}>
          <PhoneInput
            ref={phoneInputRef}
            inputProps={{
              id: props.data.code,
              name: props.data.code,
              placeholder: 'Enter phone number',
            }}
            country={'us'}
            inputStyle={{
              width: '100%',

            }}
            value={props.data.value ?? ''}
            disabled={!props.data.isEditable}
            onChange={(e) => {
              props.change(e = {
                target: {
                  name: props.data.code,
                  value: `+${e}`
                }
              }, props.data.required)
            }}
            style={{ marginRight: "15px" }}
          />
          <Button
            onClick={props.sendOTP}
            style={{ width: '35%' }}
            disabled={props.buttonActive}
          >
            send OTP
          </Button>
        </div>


      );
    } else if (type === "select" && props.data.code === "individual_type") {
      return (
        <>
          <Form.Control
            as="select"
            disabled={props.status || props.data.disabled}
            className={`${style.inputField}`}
            name={props.data.code}
            onChange={(e) => props.change(e, props.data.required)}
            // onChange={ (e)=>{props.dropdownChange(e.target.value,props.data)}}
            value={props.data.value ?? ""}
          >
            <option value="">
              {t("registration.selectOption", { field: props.data.code })}
            </option>
            {props.data.options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {t(option.code)}
                </option>
              );
            })}
          </Form.Control>
          {props.data.value === "company" && (<>
            <Form.Label
              style={{marginTop:"30px"}}
              htmlFor={props.data.code}
              className={`${props.data.required && style.required} ${props.data.error && style.isInvalid
                }`}
            >
             Company Name
            </Form.Label> 
            <Form.Control
          ref={
            props.data.code === "password" &&
              props.data.validation.disableHelper
              ? setTriggerRef
              : null
          }
          id={"companyName"}
          type={
            "text"
          }
          name={"companyName"}
          value={props.data.companyName ?? ""}
          // disabled={!props.data.isEditable}
          className={`${currentTheme == "theme-dark"
            ? `
              ${style.dark_mode_inputField}
              ${props.data.error
              ? `${style.formsHasError}form-control is-invalid`
              : ""
            }
              `
            : `
              ${style.inputField}
              ${props.data.error
              ? `${style.formsHasError}form-control is-invalid`
              : ""
            }
              `
            }`}
          onChange={(e) => props.change(e, false)}
          
        /></>)
          }
        </>
      );
    } else if (type === "select") {
      return (
        <Form.Control
          as="select"
          disabled={props.status || props.data.disabled}
          className={`${style.inputField}`}
          name={props.data.code}
          onChange={(e) => props.change(e, props.data.required)}
          // onChange={ (e)=>{props.dropdownChange(e.target.value,props.data)}}
          value={props.data.value ?? ""}
        >
          <option value="">
            {t("registration.selectOption", { field: props.data.code })}
          </option>
          {props.data.options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {t(option.code)}
              </option>
            );
          })}
        </Form.Control>
      );
    } else if (type === "select1") {
        return (
         
          <Form.Control
            id={props.data.code}
            type={type}
            name={props.data.code}
            placeholder={props.data.options.name ?? ""}
            disabled={!props.data.isEditable}
            // value={props.data.value ?? ""}
            className={`
                          ${style.inputField}
                          ${
                            props.data.error
                              ? `${style.formsHasError}form-control is-invalid`
                              : ""
                          }
                          `}
            onChange={(e) => props.change(e, props.data.required)}
          />
        );
    } else if (type === "checkbox") {
      return (
        <div className={style.checkbox}>
          <input
            type="checkbox"
            name={props.data.code}
            className={style.checkboxInput}
            onChange={(e) => props.change(e, props.data.required)}
            value={props.data.value}
            defaultChecked={props.data.value}
            // ref={el => (this.selector = el)}
            id="terms"
          // {...inputProps}
          />
          <label
            className={`${style.checkboxLabel} ${props.data.required && style.required
              }  ${props.data.error && style.isInvalid}`}
            onClick={() => props.openModal(props.data.content)}
          >
            {t("Common.acceptTermsAndCondietion")}
          </label>
        </div>
      );
    } else if (type === "date") {
      return (
        <DatePickers
          disabled={false}
          start={props.data.value}
          datechange={props.selectDate}
          id={props.data.code}
        />

      );
      }
  };
  return (
    <Form.Group>
      {props.data.code !== "agree_terms" && props.data.code !== "companyName" && (
        <Form.Label
          htmlFor={props.data.code}
          className={`${props.data.required && style.required} ${props.data.error && style.isInvalid
            }`}
        >
          {props.data.custom ?
            props.data.code
            :
            t("profile." + props.data.code)
          }
        </Form.Label>
      )}
      {Components(props.data.type)}

      {props.data.code === "password" &&
        props.data.validation.disableHelper &&
        transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                key={key}
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: "tooltip-container",
                  style: props,
                })}
              >
                <div>
                  {Object.keys(validation).map(function (key, index) {
                    if (key !== "disableHelper") {
                      return (
                        <p
                          key={index}
                          style={
                            currentTheme === "theme-dark"
                              ? { color: "white" }
                              : { color: "black" }
                          }
                        >
                          {policy[key] ? (
                            <i
                              className="fa fa-check-circle"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-times-circle"
                              style={{ color: "#f05050" }}
                            ></i>
                          )}
                          {t("validation.atleast_" + key, {
                            count: validation[key],
                          })}
                        </p>
                      );
                    }
                    return false;
                  })}
                </div>
                <div {...getArrowProps({ className: "tooltip-arrow" })} />
              </animated.div>
            )
        )}

      {/* error message */}
      <Form.Text
        className={`
                ${props.data.error ? style.isInvalid : "d-none"}
                `}
      > {props.data.custom ?
        "Please enter " + props.data.code
        :
        t(props.data.error, props.data.errorField)
        }

      </Form.Text>
    </Form.Group>
  );
}

export default FormsControl;
