import React from 'react';
import axios from "axios";
import { BASE_URL, API_KEY, } from '../configuration/apiconfig';
import UserStore from '../store/UserStore';



const instance = axios.create({ baseURL: BASE_URL })
instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 403) {
    window.location.href = '/logout';
  }
  // if (error.response.status === 403) {
  //   window.location.href = '/logout';
  // }
  else {
    return error.response.data
  }
});

instance.interceptors.request.use(
  (config) => {
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    config.headers['api-key'] = localStorage.getItem("apiKey");
    config.headers['access-token'] = UserStore.key;
    return config
  }
)
// instance.interceptors.request.clear()
export default {
  API: instance

}