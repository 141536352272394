import React from "react";
import style from "../dashboard.module.scss";
import { Row, Col, Card } from "react-bootstrap";
import { Button, Form, Image, Alert } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import proPic from "../../../../assets/images/nophoto/no_photo.jpg";
import Nophoto from "../../../../assets/images/cart/cart.jpg";
import ModalImage from "react-modal-image";
import API from "../../../../api/api";
// import sponser from "../../../../assets/images/dashboard/sponcer.png";
import PaymentMethod from "../../common/PaymentMethods";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch } from 'react-redux';
import AppinfoService from "../../../../service/common/Appinfo";
import { logout } from '../../../../store/action';
import UserStore from '../../../../store/UserStore';
import { Route, Switch, useHistory, Redirect } from "react-router-dom";

function UpgradeHome(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const currency = useSelector((state) => state.curr);
  const [payment, setPayments] = useState(false);
  const [state, setState] = useState({
    selectedFile: "",
    url: "",
    showAlert: false,
    alertType: "",
    AlertMessage: "",
    imageUploaded: false,
    paymentMethod: props?.paymentData[0] ?? 0,
    tab: 0,
    product: {
      id: "",
      amount: "",
      name: "",
    }
  });
  const commonService = new AppinfoService();
  
  const buyProduct = (product) => {
    setPayments(true);
    setState((prev) => ({
      ...prev,
      product: {
        id: product.id,
        amount: product.price,
        name: product.name,
      }
    }));

  }

  const popupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    zIndex: 1000,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    width:"auto",
    minWidth: "450px"
  };

  const tabChange = (i) => {
    setState((prev) => ({
      ...prev,
      paymentMethod: props.paymentData[i],
      tab: i
    }));
  }
  const packageUpgradeSubmit = async (data) => {
    return await API.API.post("common/purchase", data)
      .then((res) => {
        if (res.status == 204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  }

  const logoutHandler = () => {

    commonService.logOut().then((res) => {
      if (res.status) {
        dispatch(logout());
        localStorage.removeItem('login');
        localStorage.removeItem('apiKey');
        localStorage.removeItem('currency');
        UserStore.key = ''
        props.history.push('/login');

      }
      else {
        dispatch(logout());
        localStorage.removeItem('login');
        localStorage.removeItem('apiKey');
        localStorage.removeItem('currency');
        UserStore.key = ''
        history.push('/login');

      }
    })

  }

  const onsubmit = (event) => {
    if (event.hasOwnProperty('defaultPrevented')) {
      event.preventDefault()
    }
    const formData = {
      product: state.product,
      paymentMethod: state.paymentMethod 
      
      
    }
    if(state.paymentMethod.title === "stripe"){
      formData.stripe_token = event ;
    }
    packageUpgradeSubmit(formData).then((res) => {
      if (res.status) {
        setPayments(false)
        setState({
          showAlert: true,
          alertType: "success",
          AlertMessage: t("Common.Purchse_completed"),
          paymentMethod: null,
          tab: 0,
          product: {
            id: "",
            amount: "",
            name: "",
          }
        })
        
        // history.push({
        //   pathname: "/unapproved/upgrade_preview/" + res.data.username,
        //   data: {
        //     message: res.data.code,
        //     username: res.data.username,
        //   },
        // });
      } else {
        setState((prev) => ({
          ...prev,
          showAlert: true,
          alertType: "danger",
          AlertMessage: t("validation.partyError"),
        }));
      }
    });
  }
  return (
    <div style={{ marginTop: "50px" }}>
      <div className={`${style.overlay} ${payment && style.overlayActive}`}>
      </div>
      <Alert show={state.showAlert} variant={state.alertType} dismissible>
        {state.AlertMessage}
      </Alert>

      <div style={{ backgroundColor: "white", margin: "10px", padding: "10px" }}>
        <h3 style={{ margin: "20px", color: "black" }}>Shopping</h3>
        {props.products && (
          <div style={{
            display: "flex",
            flexWrap: "wrap",
          }}>
            {props.products.map((item, index) => (
              <Col key={index}
                style={{
                  flex: "1",
                  width: "100%",
                  maxWidth: "500px",
                  minWidth: "250px",
                }}
              >
                <Card style={{ boxShadow: "0px 0px 8px 6px rgb(102 96 96 / 27%)", }}>
                  <Card.Body  >
                    <div
                      style={{

                        height: "270px",
                        width: "100%"
                      }}
                    >
                      {item.image ? (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain"
                          }}
                          src={item.image}
                          alt="No Image"
                        />
                      ) : (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain"
                          }}
                          src={Nophoto}
                          alt="No Image"
                        />
                      )}
                    </div>
                    <div >
                      <h4>{item.name}</h4>
                    </div>
                    <div >
                      <span >{item.category}</span>
                    </div>
                    <div >
                      <small >
                        {currency.currentCurr}{" "}
                        {(item.price * currency.value).toFixed(currency.precesion)}
                      </small>
                    </div>
                  </Card.Body>
                  <Card.Footer >
                    <Col md={12} lg={12} style={{ padding: 0 }}>
                      <div>
                        <Button
                          variant="info"
                          onClick={() => {
                            buyProduct(item);
                          }}
                        >
                          <i className="fa fa-shopping-cart"></i>
                          {t("Button.buy")}
                        </Button>
                      </div>
                    </Col>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
            {payment &&
              (
                <div>
                 
                  <div style={popupStyle}>
                    <div style={{ display: "flex", justifyContent: 'end' }}><Button onClick={() => { setPayments(false) }}> x </Button></div>
                    <div style={{ textAlign: 'center' }}>
                    {console.log("---------------",state.product.amount)}
                      <PaymentMethod
                        methods={props.paymentData}
                        selectedTab={state.tab}
                        product={state.product.id}
                        payment_type="customer_purchase"
                        paymentMethodChange={tabChange}
                        amount={parseInt(state.product.amount).toFixed(2)}
                        {...props}
                        submit={onsubmit}
                        loader={false}
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
      </div>

    </div>

  );
}

export default UpgradeHome;


