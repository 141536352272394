import React from "react";
import { Container } from "react-bootstrap";
import style from "../dashboard.module.scss";
import Profiles from "./profiles";
import Tile from "./Tile";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function MainContent(props) {
  console.log("unapproved",props)
  const { t } = useTranslation();
  // const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <Container fluid className={`p-3`} style={props?.style}>
      {/* <NewProfile profile={props.profile}/> */}
      <div className={style.left_navigation_fold}>
        <div className={style.main_contant_sec}>
          <section className={style.contant_sec}>
            <div className={style.cntr_main_cnt_sc}>
              <div style={{ justifyContent : "center"}}className={style.dashboard_main_cnt}>
                <Profiles
                {...props}
                // uploadImgae={props.uploadImgae}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </Container>
  );
}

export default MainContent;
