import React from "react";
import { useState } from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Button, Form, Image, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "../dashboard.module.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import proPic from "../../../../assets/images/nophoto/no_photo.jpg";
import API from "../../../../api/api";
// import AlertMessage from "../common/AlertMessage";

const Profiles = React.memo((props) => {
  const history = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [payment, setPayments] = useState(false);
  const [state, setState] = useState({
    selectedFile: "",
    url: "",
    showAlert: false,
    alertType: "",
    AlertMessage: "",
    imageUploaded: false,
    tab: 0,
    product: {
      id: "",
      amount: "",
      name: "",
    },
  });

  const [tesrmsAndConsditions, setTermsAndCondition] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const userUpgradeSubmit = async (data) => {
    return await API.API.post("common/upgrade", data)
      .then((res) => {
        if (res.status ) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  }

  const handleSubmit = () => {
    if (isChecked) {
     const res = userUpgradeSubmit();
     if(res){
      setState({
        showAlert: true,
        alertType: "success",
        AlertMessage: t("Common.upgraded"),
        paymentMethod: null,
        tab: 0,
        product: {
          id: "",
          amount: "",
          name: "",
        }
      })
     }
    } else {
      setState({
        showAlert: true,
        alertType: "danger",
        AlertMessage: t("Upgradation incomplete"),
        paymentMethod: null,
        tab: 0,
        product: {
          id: "",
          amount: "",
          name: "",
        }
      })
      console.log("======= else log ==========");

    }
  };
  return (
    <>
      <div
        className={`${style.overlay} ${payment && style.overlayActive}`}
      ></div>
      <Alert show={state.showAlert} variant={state.alertType} dismissible>
        {state.AlertMessage}
      </Alert>
      {tesrmsAndConsditions ? (
        <>
          <div
            style={{ width: "100%", padding: "40px" }}
            className={`${
              currentTheme == "theme-dark"
                ? style.dark_mode_dashboard_pannel_box
                : style.dashboard_pannel_box
            }`}
          >
            <button
              style={{
                marginRight: "30px",
                borderRadius: "30px",
                fontSize: "20px",
                marginTop: "25px",
                width: "60px",
                zIndex: "999",
              }}
              className={style.logoutButton}
              onClick={() => setTermsAndCondition(false)}
            >
              <p style={{ fontSize: "15px" }}>&lt;</p>
            </button>
            <div
              style={{
                backgroundColor: "#70f3706b",
                borderRadius: "5px",
                padding: "15px",
                margin: "20px",
              }}
            >
              <p style={{ color: "#065506" }}>
                Welcome to our partner program. please read and confirm the
                terms and conditions !!!{" "}
              </p>
            </div>
            <div
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                padding: "20px",
              }}
            >
              <h3 style={{ textAlign: "center" }}>Terms And Conditions </h3>
              <p style={{ marginTop: "20px" }}  dangerouslySetInnerHTML={{ __html: props.terms }}></p>
             
              <div style={{ display: "flex", justifyContent:"space-between" ,marginTop:"10px",padding:"10px"}}>
                <div>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label style={{ marginLeft: "10px" }}>
                    {" "}
                    I agree to the terms and conditions
                  </label>
                </div>
                <br />
                <Button onClick={handleSubmit}>Submit</Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ width: "100%", padding: "40px" }}
            className={`${
              currentTheme == "theme-dark"
                ? style.dark_mode_dashboard_pannel_box
                : style.dashboard_pannel_box
            }`}
          >
            {props.lodervalue && (
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <div className={style.profile_main_sec}>
                    <div className={style.profile_main_img_bx}>
                      <SkeletonTheme baseColor="#FF5733" highlightColor="#444">
                        <Skeleton circle={true} height={90} width={90} />
                      </SkeletonTheme>
                    </div>
                    <div className={style.profile_main_txt_sc}>
                      <div>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton width={170} count={1} />
                        </SkeletonTheme>
                      </div>
                      <div>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton width={100} count={1} />
                        </SkeletonTheme>
                      </div>
                      <div>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton
                            width={120}
                            height={30}
                            count={1}
                            borderRadius={50}
                          />
                        </SkeletonTheme>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col xl={4} lg={6} md={12}>
                <div className={style.profile_main_sec}>
                  <div className={style.profile_main_img_bx}>
                    <img
                      src={props.image ? props.image : proPic}
                      alt="No Image"
                    />
                  </div>
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? style.dark_mode_profile_main_txt_sc
                        : style.profile_main_txt_sc
                    }`}
                  >
                    <h5 style={{ color: "black", fontWeight: "bold" }}>
                      {props.name}{" "}
                    </h5>
                    <span>User Name : {props.username}</span>
                    <span>Email : {props.email}</span>
                    <span>Mobile Number : {props.profileData?.mobile}</span>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={3} md={12}>
                <div
                  style={{ paddingLeft: "0px" }}
                  className={style.profile_main_sec}
                >
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? style.dark_mode_profile_main_txt_sc
                        : style.profile_main_txt_sc
                    }`}
                    style={{ paddingLeft: "0" }}
                  >
                    <h5>{props.package_details.name}</h5>
                    <span>Price : {props.package_details.price}</span>
                    <span>Rank PV : {props.package_details.pv}</span>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={3} md={12}>
                <div
                  style={{ paddingLeft: "0px" }}
                  className={style.profile_main_sec}
                >
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? style.dark_mode_profile_main_txt_sc
                        : style.profile_main_txt_sc
                    }`}
                    style={{ paddingLeft: "0" }}
                  >
                    <span>Sponser Name : {props.sponser}</span>
                    <span>Father Name : {props.father}</span>
                    <div
                      style={{
                        display: "flex",
                        position: "fixed",
                        marginTop: "60px",
                        gap: "10px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          history.push("/unapproved/shopping");
                        }}
                      >
                        {" "}
                        Purchase Now
                      </Button>

                      {props.isUpgrade === 1 && (
                        <Button
                          onClick={() => {
                            setTermsAndCondition(true);
                          }}
                        >
                          {" "}
                          Upgrade to User
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{ width: "100%", padding: "40px" }}
            className={`${
              currentTheme == "theme-dark"
                ? style.dark_mode_dashboard_pannel_box
                : style.dashboard_pannel_box
            }`}
          >
            <h2 style={{ color: "black", padding: "10px" }}>
              Customer Details
            </h2>

            {props.lodervalue && (
              <Row>
                <Col xl={12} lg={12} md={12}>
                  <div className={style.profile_main_sec}>
                    <div className={style.profile_main_txt_sc}>
                      <div>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton width={170} count={1} />
                        </SkeletonTheme>
                      </div>
                      <div>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton width={100} count={1} />
                        </SkeletonTheme>
                      </div>
                      <div>
                        <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        >
                          <Skeleton
                            width={120}
                            height={30}
                            count={1}
                            borderRadius={50}
                          />
                        </SkeletonTheme>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col xl={6} lg={6} md={12}>
                <div
                  className={style.profile_main_sec}
                  style={{ padding: "10px" }}
                >
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? style.dark_mode_profile_main_txt_sc
                        : style.profile_main_txt_sc
                    }`}
                    style={{ paddingLeft: "0", fontSize: "15px" }}
                  >
                    <h4>Personal Details</h4>
                    <span style={{ fontSize: "15px" }}>
                      Full Name : {props.profileData?.full_name}
                    </span>
                    {props.profileData?.dateOfBirth && (
                      <span style={{ fontSize: "15px" }}>
                        DOB : {props.profileData?.dateOfBirth}
                      </span>
                    )}
                    {props.profileData?.gender && (
                      <span style={{ fontSize: "15px" }}>
                        Gender : {props.profileData?.gender}
                      </span>
                    )}
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={3} md={12}>
                <div
                  style={{ paddingLeft: "0px" }}
                  className={style.profile_main_sec}
                >
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? style.dark_mode_profile_main_txt_sc
                        : style.profile_main_txt_sc
                    }`}
                    style={{ paddingLeft: "0" }}
                  >
                    <h5>Contact Details</h5>
                    <span style={{ fontSize: "15px" }}>
                      Address : {props.profileData?.addressLine}
                    </span>
                    {props.profileData?.addressLine2 && (
                      <span style={{ fontSize: "15px" }}>
                        Address Line2 : {props.profileData?.addressLine2}
                      </span>
                    )}
                    {props.profileData?.city && (
                      <span style={{ fontSize: "15px" }}>
                        City : {props.profileData?.city}
                      </span>
                    )}
                    {props.profileData?.state && (
                      <span style={{ fontSize: "15px" }}>
                        State: {props.profileData?.state}
                      </span>
                    )}
                    {props.profileData?.country && (
                      <span style={{ fontSize: "15px" }}>
                        Country : {props.profileData?.country}
                      </span>
                    )}
                    {props.profileData?.pin && (
                      <span style={{ fontSize: "15px" }}>
                        Zip code: {props.profileData?.pin}
                      </span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
});
export default Profiles;

// const onFileChange = (event) => {
//   // Update the state
//   if (event.target.files[0]) {
//     setState((prev) => ({
//       ...prev,
//       selectedFile: event.target.files[0],
//       url: URL.createObjectURL(event.target.files[0]),
//     }));
//   }
// };

// const buyProduct = (product) => {
//   console.log("clicked");
//   setPayments(true);
//   setState((prev) => ({
//     ...prev,
//     product: {
//       id: product.id,
//       amount: product.price,
//       name: product.name,
//     }
//   }));
// }

// const popupStyle = {
//   position: 'fixed',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   backgroundColor: 'white',
//   padding: '20px',
//   borderRadius: '8px',
//   zIndex: 1000,
//   boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
// };

// const tabChange = (i) => {
//   setState((prev) => ({
//     ...prev,
//     paymentMethod: props.paymentData[i],
//     tab: i
//   }));
// }

// const packageUpgradeSubmit = async (data) => {
//   return await API.API.post("common/purchase", data)
//     .then((res) => {
//       if (res.status == 204) {

//         return {
//           status: true,
//         };
//       } else {
//         return res;
//       }
//     })
//     .catch((error) => console.log(error));
// }

// const onsubmit = (event) => {
//   if (event.hasOwnProperty('defaultPrevented')) {
//     event.preventDefault()
//   }
//   const formData = {
//     product: state.product,
//     payment: state.paymentMethod
//   }
//   packageUpgradeSubmit(formData).then((res) => {
//     if (res.status) {
//       setPayments(false)
//       setState({
//         showAlert: true,
//         alertType: "success",
//         AlertMessage: t("Common.Purchse_completed"),
//         paymentMethod: null,
//         tab: 0,
//         product: {
//           id: "",
//           amount: "",
//           name: "",
//         }
//       })
//     } else {
//       setState((prev) => ({
//         ...prev,
//         showAlert: true,
//         alertType: "danger",
//         AlertMessage: t("validation.partyError"),
//       }));
//     }
//   });
// }

{
  /* <div
        style={{ width: "100%", paddingTop: "40px" }}
        className={`${payment == "true"
          ? style.overlay
          : style.dashboard_pannel_box
          }`}
      > */
}
{
  /* {props.PurhaseProducts && (
          <>
            <h3 style={{ margin: "20px", color: "black" }}>Purchase Products</h3>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {props.PurhaseProducts.map((item, index) => (
                <Col key={index} style={{ flex: "1", width: "100%", maxWidth: "750px", minWidth: "300px" }}>
                  <Card style={{ boxShadow: "0px 0px 8px 6px rgb(102 96 96 / 27%)" }}>
                    <Card.Body>
                      <div style={{ height: "270px", width: "100%" }} >
                        {item.image ? (
                          <img
                            style={{ height: "100%", width: "100%", objectFit: "contain" }}
                            src={item.image}
                            alt="No Image"
                          />)
                          : (
                            <img
                              style={{ height: "100%", width: "100%", objectFit: "contain" }}
                              src={Nophoto}
                              alt="No Image"
                            />
                          )}
                      </div>

                      <div>  <h4>{item.name}</h4> </div>
                      <div>  <span >{item.category}</span>  </div>
                      <div>  <small>
                        {currency.currentCurr}{" "}
                        {(item.price * currency.value).toFixed(currency.precesion)}
                      </small> </div>

                    </Card.Body>
                    <Card.Footer >
                      <Row >
                        <Col md={12} lg={12} style={{ padding: 0 }}>
                          <div>
                            <Button variant="info" onClick={() => { buyProduct(item) }} >
                              <i className="fa fa-shopping-cart"></i>
                              {t("Button.buy")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </div>
          </>

        )} */
}
{
  /* </div> */
}
{
  /* 
      {payment && (
        <div>
          {console.log("---------------")}
          <div style={popupStyle}>
            <div style={{ display: "flex", justifyContent: 'end' }}><Button onClick={() => { setPayments(false) }}> x </Button></div>
            <div style={{ textAlign: 'center' }}>
              <PaymentMethod
                methods={props.paymentData}
                selectedTab={state.tab}
                product={state.product.id}
                payment_type="customer_purchase"
                paymentMethodChange={tabChange}
                amount={parseInt(state.product.price).toFixed(2)}
                {...props}
                submit={onsubmit}
                loader={false}
              />
            </div>
          </div>
        </div>
      )} */
}
{
  /* <div
        style={{ width: "100%", paddingTop: "40px" }}
        className={`${payment == "true"
          ? style.overlay
          : style.dashboard_pannel_box
          }`}
      > */
}
{
  /* {props.PurhaseProducts && (
          <>
            <h3 style={{ margin: "20px", color: "black" }}>Purchase Products</h3>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {props.PurhaseProducts.map((item, index) => (
                <Col key={index} style={{ flex: "1", width: "100%", maxWidth: "750px", minWidth: "300px" }}>
                  <Card style={{ boxShadow: "0px 0px 8px 6px rgb(102 96 96 / 27%)" }}>
                    <Card.Body>
                      <div style={{ height: "270px", width: "100%" }} >
                        {item.image ? (
                          <img
                            style={{ height: "100%", width: "100%", objectFit: "contain" }}
                            src={item.image}
                            alt="No Image"
                          />)
                          : (
                            <img
                              style={{ height: "100%", width: "100%", objectFit: "contain" }}
                              src={Nophoto}
                              alt="No Image"
                            />
                          )}
                      </div>

                      <div>  <h4>{item.name}</h4> </div>
                      <div>  <span >{item.category}</span>  </div>
                      <div>  <small>
                        {currency.currentCurr}{" "}
                        {(item.price * currency.value).toFixed(currency.precesion)}
                      </small> </div>

                    </Card.Body>
                    <Card.Footer >
                      <Row >
                        <Col md={12} lg={12} style={{ padding: 0 }}>
                          <div>
                            <Button variant="info" onClick={() => { buyProduct(item) }} >
                              <i className="fa fa-shopping-cart"></i>
                              {t("Button.buy")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </div>
          </>

        )} */
}
{
  /* </div> */
}
{
  /* 
      {payment && (
        <div>
          {console.log("---------------")}
          <div style={popupStyle}>
            <div style={{ display: "flex", justifyContent: 'end' }}><Button onClick={() => { setPayments(false) }}> x </Button></div>
            <div style={{ textAlign: 'center' }}>
              <PaymentMethod
                methods={props.paymentData}
                selectedTab={state.tab}
                product={state.product.id}
                payment_type="customer_purchase"
                paymentMethodChange={tabChange}
                amount={parseInt(state.product.price).toFixed(2)}
                {...props}
                submit={onsubmit}
                loader={false}
              />
            </div>
          </div>
        </div>
      )} */
}

// {props.payment_method == "Bank Transfer" && (
//   <Row style={{ marginTop: "30px" }}>
//     <Col xl={6} lg={6} md={12} style={{ paddingLeft: "25px" }}>
//       <Form.Group>
//         <Form.Label className={style.required}>
//           {t("Common.selectRecipt")}
//         </Form.Label>
//         <Form.File onChange={onFileChange} />
//         <Form.Text className={style.textdanger}>
//           {t("Common.allowTypes")}
//         </Form.Text>
//       </Form.Group>
//       <Button
//         onClick={() => {
//           props.uploadImgae(
//             state.selectedFile,
//             props.username,
//             "register"
//           );
//         }}
//         disabled={!state.url}
//         className={style.uploadButton}
//       >
//         <i className="fa fa-arrow-circle-o-up"></i>
//         {t("Button.upload")}
//       </Button>
{
  /* {state.url && (
<Form.Group>
<Image src={state.url} className={style.recieptPreview} />
<Button className={style.RecieptRemoveButton} onClick={removeImage}>
  <i className="fa fa-times"></i>
  Remove
</Button>
</Form.Group>
)} */
}
{
  /* </Col> */
}
{
  /* { props.payment_method == "Bank Transfer" &&  */
}

{
  /* {props?.receipt_url && (
      <Col xl={6} lg={6} md={12}>
        <h5 style={{ color: "black", fontWeight: "bold" }}>
          Uploaded Receipt
        </h5>
        <div>Please click on image for enlarging</div>
        <div className={style.image_Preview}>
          <ModalImage
            small={props?.receipt_url}
            large={props?.receipt_url}
            alt="Uploaded File"
          />
        </div>
      </Col> */
}
{
  /* )} */
}
{
  /* } */
}
{
  /* </Row>
)} */
}
