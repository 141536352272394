import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Table,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./profile.module.scss";
import ProfileService from "../../../service/profile";
import { DropDownBs, AddonNotification } from "../common";
import { useSelector } from "react-redux";
import { ComplyCube } from '@complycube/api';

function KycContent(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new ProfileService();
  const { t } = useTranslation();
  let itemEls = [];
  itemEls = useRef(new Array());
  const [fileData, setFileData] = useState({});
  const [fileData1, setFileData1] = useState({});

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1];
        setFileData({
          data: base64Data,
          filename: file.name,
          size: file.size,
        });
      };
      if (file.type.startsWith("image/") || file.type === "application/pdf") {
        reader.readAsDataURL(file);
      } else {
        console.error("Unsupported file type");
      }
    }
  };
  const handleFileChange1 = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1];
        setFileData1({
          data: base64Data,
          filename: file.name,
          size: file.size,
        });
      };
      if (file.type.startsWith("image/") || file.type === "application/pdf") {
        reader.readAsDataURL(file);
      } else {
        console.error("Unsupported file type");
      }
    }
  };
  console.log(fileData);
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    lastName: "",
  });
  const [state, setState] = useState({
    category: [],
    kyc: props.data.kycStatus,
    selectedFile: [
      {
        file: "",
      },
    ],
    alert: {
      show: false,
      message: "",
      variant: "",
    },
    ImgPreview: {
      show: false,
      url: "",
    },
  });
  useEffect(() => {
    if (props.data.category && props.data.category.length > 0) {
      setState((prev) => ({
        ...prev,
        category:
          state.category.length > 0
            ? state.category[0]
            : props.data.category[0].id,
      }));
    }
  }, [props.data]);

  //change handler
  const changeHandler = (e) => {
    let { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  //file change event
  const onFileChange = (event, index) => {
    if (event.target.files[0]) {
      let file = state.selectedFile;
      file[index] = event.target.files[0];
      setState((prev) => ({
        ...prev,
        selectedFile: file,
      }));
    }
    console.log(state.selectedFile);
  };

  //file validation
  const validation = (element) => element.file === "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const KYCVerification = () => {
    formData.file = fileData;
    if(props.data.company){
      formData.file1 = fileData1;
    }
    console.log("Form Data:", formData);

    service.verifyKyc(formData).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          alert: {
            show: true,
            variant: "success",
            message: t("Common.KYC_verification_inprogress")
          },
        }));
        setFormData({
          firstName: "",
          email: "",
          lastName: "",
          file: {},
        });
      } else {
        let message = res.error.description;
        setState((prev) => ({
          ...prev,
          alert: {
            show: true,
            variant: "danger",
            message: message,
          },
        }));
      }
    });
  };

  //upload kyc
  const uploadKyc = () => {
    if (state.selectedFile.some(validation)) {
      setState((prev) => ({
        ...prev,
        alert: {
          show: true,
          variant: "danger",
          message: "chooseFile",
        },
      }));
    } else if (state.category.length == 0) {
      setState((prev) => ({
        ...prev,
        alert: {
          show: true,
          variant: "danger",
          message: "chooseCategory",
        },
      }));
    } else {
      let formData = new FormData();
      formData.append("category", state.category);
      for (const key of Object.keys(state.selectedFile)) {
        formData.append("id_proof", state.selectedFile[key]);
      }
      service.uploadFile(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              variant: "success",
              message: "idUploadSuccessfully",
            },
          }));
          state.selectedFile.map(
            (fileitams, index) => (itemEls.current[index].value = "")
          );
          setState((prev) => ({
            ...prev,
            selectedFile: [
              {
                file: "",
              },
            ],
          }));
          props.loadData();
        } else {
          let message = res.error.description;

          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              variant: "danger",
              message: message,
            },
          }));
        }
      });
    }
  };

  //add file input file field
  const addfileinput = () => {
    if (state.selectedFile.length <= 1) {
      setState((prev) => ({
        ...prev,
        selectedFile: [
          ...prev.selectedFile,
          {
            file: "",
          },
        ],
      }));
    } else {
      let file = state.selectedFile;
      file.splice(-1);
      setState((prev) => ({
        ...prev,
        selectedFile: file,
      }));
    }
  };

  //close alert
  const closeAlert = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        ...prev.alert,
        show: false,
      },
    }));
  };

  //delete kyc files
  const deletefiles = (id) => {
    if (id) {
      if (window.confirm(t("validation.sureWantToDelete"))) {
        let idData = {
          id: id,
        };
        service.deletefile(idData).then((res) => {
          if (res.status) {
            setState((prev) => ({
              ...prev,
              alert: {
                show: true,
                variant: "success",
                message: "succussfullydeleted",
              },
            }));
            props.loadData();
          }
        });
      }
    }
  };

  //close modal
  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      ImgPreview: {
        ...prev.ImgPreview,
        show: false,
      },
    }));
  };

  //show modal
  const handleShow = (url) => {
    setState((prev) => ({
      ...prev,
      ImgPreview: {
        ...prev.ImgPreview,
        show: true,
        url: url,
      },
    }));
  };
  return (
    <div className={style.MainContainer}>
      {/* <AddonNotification /> */}
      <div className={style.KycDetials}>
        <Card className={style.card}>
          <Card.Body className={style.cardBody}>
            <Alert
              variant={state.alert.variant}
              dismissible
              show={state.alert.show}
              onClose={closeAlert}
            >
              {state.alert.message}
            </Alert>
            <Row>
              <Col md={6}>

                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    placeholder="Enter Name"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    placeholder="Enter second Name"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formData.email}
                    placeholder="Enter Email"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.File>
                  <Form.File.Label>{t("Common.uploadfile")}</Form.File.Label>
                  <Form.File.Input onChange={handleFileChange} multiple />
                  <Form.Text>{t("Common.allowTypes")}</Form.Text>
                </Form.File>
                <br />
              </Col>
              {props.data.company && (
              <Col md={6}>
                <Form.File>
                  <Form.File.Label>{t("Common.uploadCompanycertificate")}</Form.File.Label>
                  <Form.File.Input onChange={handleFileChange1} multiple />
                  <Form.Text>{t("Common.allowTypes")}</Form.Text>
                </Form.File>
                <br />
              </Col>)}
            </Row>
            <Row>
              <Col md={6}>
                <div className={style.formButtons}>
                  <Button
                    variant="primary"
                    onClick={KYCVerification}
                    disabled={props.data.kycStatus || state.kyc}
                  >
                    Verify
                  </Button>
                  {props.data.kycStatus || state.kyc ? (
                    <p style={{ color: "red" }}>KYC verification Completed</p>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal
          show={state.ImgPreview.show}
          onHide={handleClose}
          contentClassName={style.imgPreviewContent}
        >
          <Modal.Body>
            <img src={state.ImgPreview.url} alt="" />
          </Modal.Body>
          <Modal.Footer className={style.PreviewFooter}>
            <Button
              variant="secondary"
              onClick={handleClose}
              className={style.closeButton}
            >
              {t("Button.close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default KycContent;
