import React from "react";
import { useTranslation } from "react-i18next";
import { FormInputControl, DropDownBs, DatePickers } from "../common";

function FormsControl(props) {
  const { t } = useTranslation();

  const Components = (type) => {
    if (
      (type === "text" || type === "email" || type === "textarea" || type === "number") &&
      props.data.code !== "companyName"
    ) {
      return (
        <FormInputControl
          label={props.data.custom ? props.data.code : t("profile." + props.data.code)}
          required={props.data.required}
          inputProps={{
            id: props.data.code,
            name: props.data.code,
            type: type,
            value: props.data.value ?? "",
            disabled: props.status,
            onChange: (e) => props.change(e, props.data.required),
          }}
          error={{
            error: props.data.error,
            field: props.data.errorField,
          }}
        />
      );
    } else if (type === "select") {
      return (
        <>
          <DropDownBs
            label={t("profile." + props.data.code)}
            required={props.data.required}
            preOption={props.data.code}
            options={{
              data: props.data.options,
              value: "value",
              label: "code",
            }}
            DropdownProps={{
              disabled: props.status,
              onChange: (e) => {
                props.dropdownChange(e.target.value, props.data);
              },
              value: props.data.value ?? "",
            }}
          />
          {props.data.value === "company" && (
            <FormInputControl
              label={t("profile.companyName")}
              required={false}
              inputProps={{
                id: "companyName",
                name: "companyName",
                type: "text",
                value: props.companyName,
                disabled: props.status,
                onChange: (e) => props.CompanyChange(e),
              }}
              error={{
                error: props.data.error,
                field: props.data.errorField,
              }}
            />
          )}
        </>
      );
    } else if (type === "date") {
      return (
        <DatePickers
          disabled={props.status}
          start={props.data.value}
          dateChange={props.dateChange}
          id={props.data.code}
          label={t("profile." + props.data.code)}
          error={{
            error: props.data.error,
            field: props.data.errorField,
          }}
        />
      );
    } else {
      return null; // Return null for unrecognized types
    }
  };

  // Call Components function and return its result
  return Components(props.data.type);
}

export default FormsControl;
