//demo

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://162.19.146.135:1298/node_api/web/";
// const IMG_URL = "http://162.19.146.135:1298/node_api/uploads/images/";
// const LEAD_URL = "http://162.19.146.135:1298/node_api/web/";
// const API_KEY =localStorage.getItem('apiKey'); 
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://162.19.146.135:1298/";

//local

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.9.47:5001/node_api/web/";
// const IMG_URL = "http://192.168.9.47:5001/node_api/uploads/images/";
// const LEAD_URL = "http://192.168.9.47:5001/node_api/web/";
// const API_KEY =localStorage.getItem('apiKey'); 
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.9.47:5001/";

//live

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.10.40:5004/node_api/web/";
// const IMG_URL = "http://192.168.10.40:5004/node_api/uploads/images/";
// const LEAD_URL = "http://192.168.10.40:5004/node_api/web/";
// const API_KEY =localStorage.getItem('apiKey'); 
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e';
// const BASE = "http://192.168.10.40:5004/";


import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://admin.vivaverde.ventures/node_api/web/";
const IMG_URL = "https://admin.vivaverde.ventures/node_api/uploads/images/";
const LEAD_URL = "https://admin.vivaverde.ventures/node_api/web/"; 
const API_KEY =localStorage.getItem('apiKey'); 
const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
const BASE = "https://vivaverde.ventures";

export {    
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY,
   LEAD_URL
}

