import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Image, Alert } from "react-bootstrap";
import style from "./payment.module.scss";
import Payments from "../../../service/common/PaymentApi";
import { useSelector } from "react-redux";

function BankTransfer(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new Payments();
  const { t } = useTranslation();
  const [state, setState] = useState({
    selectedFile: null,
    url: "",
    accoundDetails: "",
    enableFinish: false,
    showAlert: false,
    alertType: "",
    AlertMessage: "",
    imageUploaded: false,
    loader: false,
    qr: ""
  });
  useEffect(() => {
    const accoundService = new Payments();
    accoundService.accoundDetails().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          accoundDetails: res.data.bankDetails,
          qr: res.data.bankDetails.qr,
          enableFinish: true,
        }));
      }
    });
  }, []);

  //file change event
  const onFileChange = (event) => {
    // Update the state
    if (event.target.files[0]) {
      setState((prev) => ({
        ...prev,
        selectedFile: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
      }));
    }
  };
  //upload image
  const uploadImgae = () => {
    const formData = new FormData();
    formData.append("file", state.selectedFile);
    formData.append("user_name", props.userName ?? "");
    formData.append("type", props.type ?? "subscription_renewal");
    if (window.location.pathname.includes("/replica_register")) {
      service.upload_repllicaReciept(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            enableFinish: true,
            showAlert: true,
            alertType: "success",
            AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
            imageUploaded: true,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            enableFinish: false,
            showAlert: true,
            alertType: "danger",
            AlertMessage: t("Common.error_file_upload"),
          }));
        }
      });
    } else {
      service.upload_reciept(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            enableFinish: true,
            showAlert: true,
            alertType: "success",
            AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
            imageUploaded: true,
          }));
        } else if (res.error.code === 1018) {
          console.log("enterdd here....");
          setState((prev) => ({
            ...prev,
            enableFinish: false,
            showAlert: true,
            alertType: "danger",
            AlertMessage: t("Common.fileTypeExceeded"),
          }));
        } else {
          setState((prev) => ({
            ...prev,
            enableFinish: false,
            showAlert: true,
            alertType: "danger",
            AlertMessage: t("Common.error_file_upload"),
          }));
        }
      });
    }
  };

  //remove image
  const removeImage = () => {
    setState((prev) => ({
      ...prev,
      selectedFile: "",
      url: "",
      enableFinish: false,
      showAlert: false,
    }));
    if (state.imageUploaded) {
      const username = {
        user_name: props.userName,
      };
      service.removeImage(username).then((res) => { });
    }
  };
  return (
    <div className={style.MianContent}>
      <Alert show={state.showAlert} variant={state.alertType} dismissible>
        {state.AlertMessage}
      </Alert>

      <Form
        onSubmit={(e) => {
          setState((prev) => ({
            ...prev,
            loader: true,
          }));
          window.location.pathname.includes("/register") ||
            window.location.pathname.includes("/replica_register")
            ? props.submit(e, "", "payment")
            : props.submit(e, "");
        }}
      >
        <Form.Group>
          <Form.Label>{t("Common.bankDetails")}</Form.Label>
          {/* <Form.Control
          as="textarea"
          inputClass={`${
            currentTheme == "theme-dark" &&
            `${style.dark_mode_textArea}`
          }`}
          rows={10}
          readOnly
          className={style.bankDetails}
          value={state.accoundDetails.details}
        /> */}

          {state.accoundDetails && (
            <div>


              <p>{state.accoundDetails.details.bank}</p>
              <p>Account Owner: {`${state.accoundDetails.details.nacct_holder} ${""}${state.accoundDetails.details.branch} ${" "} ${state.accoundDetails.details.ifsc}${" "} ${state.accoundDetails.details.pan}`}</p>
              <p>
                IBAN: {state.accoundDetails.details.account_number}
              </p>
            </div>
          )}
        </Form.Group>
        {state.qr &&
          (
            <Form.Group>
              <Form.Label>{t("Common.QR")}</Form.Label>
              <Image src={state.qr} className={style.recieptPreview} />
            </Form.Group>
          )}
        {/* <Form.Group>
          <Form.Label className={style.required}>
            {t("Common.selectRecipt")}
          </Form.Label>
          <Form.File onChange={onFileChange} />
          <Form.Text className={style.textdanger}>
            {t("Common.allowTypes")}
          </Form.Text>
        </Form.Group> */}
        {state.url && (
          <Form.Group>
            <Image src={state.url} className={style.recieptPreview} />
            <Button className={style.RecieptRemoveButton} onClick={removeImage}>
              <i className="fa fa-times"></i>
              Remove
            </Button>
          </Form.Group>
        )}
        <Form.Group className={style.Buttons}>
          {/* <Button
            onClick={uploadImgae}
            disabled={!state.url}
            className={style.uploadButton}
          >
            <i className="fa fa-arrow-circle-o-up"></i>
            {t("Button.upload")}
          </Button> */}
          {/* {
                            props.prevButtonClick&&
                                <Button onClick={props.prevButtonClick} className={style.PrevButton}>{t('profile.prev')}</Button>
                        } */}
          <Button
            disabled={!state.enableFinish ? true : props.loader ? true : false}
            type="submit"
            className={style.NextButton}
          >
            {props.loader ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : (
              ""
            )}
            {t("Button.finish")}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default BankTransfer;
