import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout";
import ProfileService from "../../../service/profile";
import _ from "lodash";
import Payments from "../../../service/common/PaymentApi";

function Striperes(props) {
  console.log("========================= inside ===================");
  console.log(props);
  let stripePromise = "";
  const [clientSecret, setClientSecret] = useState("");
  const [stripeData, setstripeData] = useState({
    status: false,
    email: "",
    name: "",
    phone: "",
    desc: "",
    userAction: "",
    username : ""
  });
  const service = new Payments();
  const paymentService = new ProfileService();
  useEffect(() => {
    service.createPayment(props.product, "Stripe").then((res) => {
      if (res.status) {
        console.log(res.data.public_key);
        setClientSecret(res.data.public_key);
      }
    });
    getUserdetails();
  }, []);

  const getUserdetails = async () => {
    
    if (
      window.location.pathname.includes("/register") ||
      window.location.pathname.includes("/replica_register")
    ) {
      console.log("props.conatct === ", props.conatct);
      const email = _.find(props.conatct, { code: "email" });
      const name = _.find(props.conatct, { code: "firstName" });
      const phone = _.find(props.conatct, { code: "mobile" });
      setstripeData((prev) => ({
        ...prev,
        status: true,
        name: name.value,
        email: email.value,
        phone: phone.value,
        username: name.value,
        desc: `${
          window.location.pathname.includes("/replica_register")
            ? "Replica Registration"
            : "Registration"
        }for ${email.value}`,
        userAction: window.location.pathname.includes("/replica_register")
          ? "replica_register"
          : "register",
      }));
    } else if (window.location.pathname.includes("/checkOut")) {
      setstripeData((prev) => ({
        ...prev,
        status: true,
        username:localStorage.getItem("username"),
        userAction: "repurchase",
      }));
    } else if (window.location.pathname.includes("/shopping")) {
      setstripeData((prev) => ({
        ...prev,
        status: true,
        username:localStorage.getItem("username"),
        userAction: "customer_purchase",
      }));
    }
  };


  if (clientSecret != "") {
    stripePromise = loadStripe(clientSecret);
  }
  return (
    <div className="app" style={{minWidth:"400px"}}>
      {clientSecret != "" && stripePromise && stripeData.status && (
        <Elements stripe={stripePromise}>
          <Checkout
            {...props}
            stripeData={stripeData}
            createFunctionToCall={props.createFunctionToCall}
          />
        </Elements>
      )}
    </div>
  );
}
export default Striperes;
