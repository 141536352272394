import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import sponser from "../../../assets/images/dashboard/sponcer.png";
import TileItems from "./TileItems";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function Tile(props) {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  const handleButtonClick = () => {
    setRedirectToDashboard(true);
  };

  if (redirectToDashboard) {
    return <Redirect to="/shopping" />;
  }
  return (
    <div className={style.dashboard_boxs_listing_sponcer_sec}>
      <Row className="fll_wdth_row_tab">
        <Col md={7} className={style.mid_flll_wdth}>
          {props.lodervalue && (
            <div className={style.boxes_lst_sec}>
              {[1, 2, 3, 4].map((items, index) => {
                return (
                  <div className={style.list_boxes_main}>
                    <div
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_dashboard_pannel_box} `
                          : `${style.dashboard_pannel_box}`
                      }`}
                    >
                      <div className={style.listing_box_sec}>
                        <div>
                          <SkeletonTheme
                            baseColor="#202020"
                            highlightColor="#444"
                          >
                            <Skeleton circle={true} height={90} width={90} />
                          </SkeletonTheme>
                        </div>
                        <div className={style.listing_box_head}>
                          <SkeletonTheme
                            baseColor="#202020"
                            highlightColor="#444"
                          >
                            <Skeleton width={100} count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className={style.listing_box_cnout}>
                          <SkeletonTheme
                            baseColor="#202020"
                            highlightColor="#444"
                          >
                            <Skeleton width={50} count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {props.Tiles && props.Tiles.length > 0 && (
            <div className={style.boxes_lst_sec}>
              {props.Tiles.map((items, index) => {
                return (
                  <TileItems
                    amount={items.amount}
                    text={items.text}
                    to={items.to}
                    key={index}
                    boxname={index}
                    filter={items.filter}
                    filterChange={props.filterChange}
                    lodervalue={props.lodervalue}
                    amount_withcurrency={items.withcurrency}
                  />
                );
              })}
            </div>
          )}
        </Col>
        <Col md={5} className={style.mid_flll_wdth}>
          {props.lodervalue && (
            <div className={style.sponcer_sec}>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_pannel_box}`
                    : `${style.dashboard_pannel_box}`
                }`}
              >
                <div className={style.sponcer_top_name_sec}>
                  <div className={style.sponcer_top_name_txt}>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton width={170} count={1} />
                      <Skeleton width={110} count={1} />
                    </SkeletonTheme>
                  </div>

                  <div className={style.sponcer_top_ico}>
                    {" "}
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton width={60} height={80} count={1} />
                    </SkeletonTheme>
                  </div>
                  <div className={style.spouncer_cnt_row}>
                    {[1, 2, 3, 4].map((data, index) => {
                      return (
                        <div className={style.spouncer_cnt_boxes} key={index}>
                          <SkeletonTheme
                            baseColor="#202020"
                            highlightColor="#444"
                          >
                            <Skeleton width={110} count={1} />
                            <Skeleton width={80} count={1} />
                          </SkeletonTheme>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {props.SponserData && (
            <div className={`${style.sponcer_sec}`}>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_pannel_box}`
                    : `${style.dashboard_pannel_box}`
                }`}
              >
                <>
                  {props.SponserData[0].text === "sponsorName" && (
                    <div
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_sponcer_top_name_sec}`
                          : `${style.sponcer_top_name_sec}`
                      }`}
                    >
                      <div
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_sponcer_top_name_txt}`
                            : `${style.sponcer_top_name_txt}`
                        }`}
                      >
                        <span>{t("Common.sponsor")}</span>
                        {props.SponserData[0].head}
                      </div>

                      <div className={style.sponcer_top_ico}>
                        <img
                          src={sponser}
                          alt=""
                          style={{
                            mixBlendMode:
                              currentTheme == "theme-dark" ? "multiply" : "",
                          }}
                        />
                      </div>
                      <div>
                        <button
                          style={{
                            padding: "10px",
                            color: "white",
                            width: "25%",
                            borderRadius: "10px",
                            backgroundColor: "#7266ba",
                           marginLeft:"44%",
                           marginTop:"5%"
                          }}
                          onClick={handleButtonClick}
                        >
                          Purchase Now
                        </button>
                      </div>
                    </div>
                  )}

                  <div className={style.spouncer_cnt_row}>
                    {/* {props.pvData.map((data, index) => {
                      return (
                        <div
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_spouncer_cnt_boxes}`
                              : `${style.spouncer_cnt_boxes}`
                          }`}
                          key={index}
                        >
                          {t("Common." + data.text)}
                          <strong>{data.head}</strong>
                        </div>
                      );
                    })} */}
                  </div>
                </>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Tile;
