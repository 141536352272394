import React, { forwardRef, useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
import ModernDatepicker from "react-modern-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "./Common.module.scss";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IMG_URL } from "../../../configuration/apiconfig";
import moment from "moment";

const stylees = {
  "z-index": "1000 !important",
};

function DatePickers(props) {
  const [startDate, setStartDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [state, setState] = useState({
    startDate: new Date(props.start),
  });

  const handleChange = (date) => {
    setState({
      startDate: date,
    });
  };

  useEffect(() => {
    if (props.start) {
      setStartDate(props.start);
    } else {
      setStartDate(null);
    }
    //check the min date
    if (props.minDate) {
      setMinDate(props.minDate);
    } else {
      setMinDate(null);
    }
    //set the max date
    if (props.maxDate) {
      setMaxDate(props.maxDate);
    } else {
      setMaxDate(null);
    }
  }, [props.start]);
  const { t } = useTranslation();
  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    // <button className="example-custom-input" onClick={onClick} ref={ref}>
    //   {value}
    // </button>
    <div className={style.datePickerForm}>
      <Form.Control
        className={`
                ${style.dateInput}
                ${style.formsInputField}
                ${
                  props.error && props.error.error && !props.textSuccess
                    ? `${style.FormsHasError} form-control is-invalid`
                    : ""
                }
            `}
        onFocus={onClick}
        // dangerouslySetInnerHTML={{__html:value}}
        value={value}
        placeholder={props.placeholderText}
        readOnly
      />
    </div>
  ));
  return (
    <Form.Group className={`${style.formGroup} ${props.formClass}`}>
      {props.label && (
        <Form.Label
          className={props.required && style.required}
          {...props.labelProps}
        >
          {props.label}
        </Form.Label>
      )}

      {/* <DatePicker 
          selected={startDate} 
          onChange={date => props.datechange(date,props.id)} 
          className={style.datePicker}
          disabled={props.disabled}
          dateFormat='dd/MM/yyyy'
          placeholderText={props.placeholderText}
          customInput={<DatePickerInput/>}
          closeOnScroll={true}
          minDate={minDate}
          maxDate={maxDate}
        /> */}
      <div style={props.disabled ? { pointerEvents: "none" } : {}}>
        <ModernDatepicker
          className={style.datePicker}
          style={stylees}
          date={startDate}
          //onChange={date => handleChange(date)}
          onChange={(date) => props.datechange(date, props.id)}
          // format={'dd/MM/yyyy'}
          format={"DD-MM-YYYY"}
          placeholder={props.placeholderText}
          minDate={minDate}
          maxDate={maxDate}
          showBorder={true}
          closeOnScroll={true}
        ></ModernDatepicker>
      </div>
      <Form.Text
        className={`
                ${
                  props.error && props.error.error
                    ? !props.textSuccess && style.isInvalid
                    : "d-none"
                }
                `}
      >
        {props.textIcon && [
          props.textSuccess ? (
            <img
              src={`${IMG_URL}/public_html/images/accepted.png`}
              alt=""
              key="success"
              className={style.textSuccess}
            />
          ) : (
            <i
              className="fa fa-times-circle"
              style={{ color: "red" }}
              key="failiure"
            ></i>
          ),
        ]}
        &nbsp;
        {props.error && t(props.error.error, props.error.field)}
      </Form.Text>
    </Form.Group>
  );
}

export default DatePickers;
